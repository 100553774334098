import Vue from 'vue';

Vue.directive('draggable', {
  bind(el, binding) {
    let startX, startY, initialMouseX, initialMouseY;

    // 获取初始位置
    function startDrag(e) {
      e.preventDefault();
      if (
        e.target.tagName.toLowerCase() === 'i' ||
        e.target.classList.contains('el-slider__button') ||
        e.target.classList.contains('el-switch__core')
      ) {
        // 如果事件来自输入框、滑块或开关，则不执行拖动操作
        return;
      }

      if (
        e.target.tagName.toLowerCase() === 'input'
      ) {
        // 如果事件来自输入框、滑块或开关，则不执行拖动操作
        $('#inputs').focus();
        return;
      }else{
        $('#inputs').blur();
      }


      startX = el.offsetLeft;
      startY = el.offsetTop;

      // 判断是否是鼠标事件还是触摸事件
      initialMouseX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
      initialMouseY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;

      document.addEventListener('mousemove', drag);
      document.addEventListener('mouseup', stopDrag);
      document.addEventListener('touchmove', drag);
      document.addEventListener('touchend', stopDrag);
    }

    // 拖动对话框
    function drag(e) {
      e.preventDefault();
      const currentMouseX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
      const currentMouseY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;
      const dx = currentMouseX - initialMouseX;
      const dy = currentMouseY - initialMouseY;

      // 更新对话框位置
      el.style.left = `${startX + dx}px`;
      el.style.top = `${startY + dy}px`;
    }

    // 停止拖动
    function stopDrag() {
      document.removeEventListener('mousemove', drag);
      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('touchmove', drag);
      document.removeEventListener('touchend', stopDrag);
    }

    el.addEventListener('mousedown', startDrag);
    el.addEventListener('touchstart', startDrag);
  }
});
