const state = {
  dataInfo: '',
}

const mutations = {
  setParams: (state,data) => {
    state.dataInfo = data
  },
 
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

