<template>
	<!-- 分页 -->
	<div class="flex-center mt-20">
		<span>共{{total}}条</span>
		<el-pagination
		  prev-text="上一页"
		  next-text="下一页"
		  background
		  layout="prev, pager, next"
		  :total="total"
		  :currentPage="currentPage"
		  :page-size="10"
		  @current-change="currentChange"
		  >
		</el-pagination>
	</div>
</template>

<script>
	export default {
		props:["total"],
		data(){
			return{
				currentPage:1
			}
		},
		methods:{
			currentChange(e){
				this.currentPage = e;
				this.$emit("change",e)
			}
		}
	}
</script>

<style scoped>
	::v-deep .btn-prev{
		padding: 2px 10px !important;
		border: 1px solid #ddd;
	}
	::v-deep .btn-next{
		padding: 2px 10px !important;
		border: 1px solid #ddd;
	}
	::v-deep .btn-prev>span{
		/* line-height: 25px; */
	}
	::v-deep .btn-next>span{
		/* line-height: 25px; */
	}
</style>