import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './config/rem'//rem布局
import '@/styles/index.scss'

import '@/icons' // icon
import '@/permission'
import qs from 'qs'
import utils from './utils/utils'
import funs from './utils/fun.js'
import $ from "jquery";
import '@/utils/tap/drag';//拖拽
import '@/utils/tap/dialogDrag';//拖拽

window.jQuery = $;
window.$ = $;
// 文件上传组件
import FileUpload from "@/components/FileUpload"
Vue.component('FileUpload', FileUpload)
import pagination from '@/components/pagination/index';
import model from './plugins/modal.js'
// 隐藏 右侧浏览器 滚动条
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll)
//引入
import socketPublic from '@/utils/websocket.js'
Vue.prototype.$socketPublic = socketPublic
Vue.component('pagination', pagination);
/* 流程日志 */
import logStatus from '@/components/logStatus/index';
Vue.component('logStatus', logStatus);
/* 全局变量 */
import globalVariable from '@/utils/globalVariable.js';

import pdfView from "@/components/pdf/pdfView"; //pdf预览
Vue.component('pdfView', pdfView)

Vue.prototype.$global = globalVariable;

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$model = model;
Vue.prototype.$utils = utils;
Vue.prototype.qs = qs;
Vue.prototype.$funs = funs;
Vue.prototype.$EventBus = new Vue();
import preventBack from 'vue-prevent-browser-back' //禁止浏览器返回
global.preventBack = preventBack;
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')