const utils = {
	isEmpty(ele) {
		if (typeof ele === 'undefined') {
			return true;
		} else if (ele == null) {
			return true;
		} else if (ele == 'null') {
			return true;
		} else if (ele == '') {
			return true;
		}
		return false;
	},
	strEmpty(ele) {
		if (typeof ele === 'undefined') {
			return "";
		} else if (ele == null) {
			return "";
		} else if (ele == 'null') {
			return "";
		} else if (ele == '') {
			return "";
		}
		return ele;
	},
	numEmpty(ele) {
		if (typeof ele === 'undefined') {
			return "0";
		} else if (ele == null) {
			return "0";
		} else if (ele == 'null') {
			return "0";
		} else if (ele == '') {
			return "0";
		}
		return ele;
	},
};

export default utils
