module.exports = {

  title: '企安道-中小微企业云端法务部',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

}
