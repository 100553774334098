<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	import {
		getToken,
		removeToken
	} from '@/utils/auth'
	import {
		getCurrentUser
	} from "@/api/user"
	export default {
		name: 'App',
		watch: {
			 '$route.path': function(newPath, oldPath) {
			  // 路由发生变化时的处理逻辑
			  console.log('路径已变更：从' + oldPath + '到' + newPath);
			  if(getToken()){
				  // this.getUserInfo()
			  }else{
				 this.logout() 
			  }
			}
		},
		mounted() {
			if(getToken()){
				this.$socketPublic.dispatch('webSocketInit'); //初始化ws
				
			}
		},
		methods: {
			logout() {
				this.$socketPublic.dispatch('closeWebSocket');
				removeToken();
				this.$router.push(`/login?redirect=${this.$route.fullPath}`)
			},
			/* 获取用户信息 */
			getUserInfo() {
				console.log("this.$store==========",this.$store.state.user.vipEndTimeStatus);
				getCurrentUser().then((res) => {
					if(res.data.endTime.indexOf("-")!==-1){
						let isEnd = true;
						this.$store.commit("user/SET_ENDTIMESTATUS",isEnd)
					}else{
						this.$store.commit("user/SET_ENDTIMESTATUS",false)
					}
				});
			},
		},
	}
</script>
<style>
	body {
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		-ms-user-select: none;
	}

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		background: rgba(0, 0, 0, 0.1);
		border-radius: 0;
	}

	::-webkit-scrollbar-thumb {
		cursor: pointer;
		border-radius: 5px;
		background: rgba(0, 0, 0, 0.15);
		transition: color 0.2s ease;
	}

	::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
	}

	::-moz-scrollbar {
		-webkit-appearance: none;
		width: 6px;
		height: 6px;
	}

	::-moz-scrollbar-track {
		background: rgba(0, 0, 0, 0.1);
		border-radius: 0;
	}

	::-moz-scrollbar-thumb {
		cursor: pointer;
		border-radius: 5px;
		background: rgba(0, 0, 0, 0.15);
		transition: color 0.2s ease;
	}

	::-moz-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.3);
	}

	textarea::placeholder {
		color: #000 !important;
	}

	input::placeholder {
		color: #545454 !important;
	}
</style>
<style lang="scss">
	@import url("@/assets/style/common.scss");
</style>