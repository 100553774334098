/* 用于判断组件类型页面的显示隐藏状态管理 */

const state = {//获取get值：this.$store.state.params
	params:"",
	pageData:"",
	configKey:""
}

const mutations = {//改变set值：this.$store.commit("setParams", value);
  /* 页面状态 */
  setParams(state,value){ state.params = value; },
  setPageData(state,value){ state.pageData = value; },
  setConfigKey: (state,data) => {
    state.configKey = data
  }
}
const actions = {}

export default {
  state,
  mutations,
  actions
}