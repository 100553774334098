import request from '@/utils/request'

// 获取关于我们
export function getAboutInfo(data) {
	return request({
		url: "/system/aboutInfo/public/getInfo",
		method: 'get',
		params: data
	})
}

// 获取帮助中心列表
export function getHelpList(data) {
	return request({
		url: "/system/help/public/getHelpList",
		method: 'get',
		params: data
	})
}

//获取帮助中心详情
export function getHelpInfo(id) {
	return request({
		url: "/system/help/public/getHelpInfo/" + id,
		method: 'get',
	})
}

//获取法律风险体验-类型
export function riskCategory(data) {
	return request({
		url: '/case/riskCategory/getList',
		method: 'get',
		params: data
	})
}
//获取法律风险体验-获取答题题目列表
export function riskSubjectList(data) {
	return request({
		url: '/case/riskSubject/getList',
		method: 'get',
		params: data
	})
}
//添加体验记录sfs
export function riskCheckup(data) {
	return request({
		url: "/case/riskCheckup",
		method: 'post',
		data: data
	})
}
//获取体验详情
export function getCheckupInfo(data) {
	return request({
		url: "/case/riskCheckup/getCheckUpInfo",
		method: 'get',
		params: data
	})
}
//获取体验记录
export function getCheckupListByUser(data) {
	return request({
		url: "/case/riskCheckup/getCheckupListByUser",
		method: 'get',
		params: data
	})
}
// ----------------------------------非诉讼--------------------------开始
//新增法律咨询
export function addNonLegalAdvice(data) {
	return request({
		url: '/portal/case/addNonLegalAdvice',
		method: 'post',
		data: data
	})
}
//修改法律咨询
export function editNonLegalAdvice(data) {
	return request({
		url: '/portal/case/editNonLegalAdvice',
		method: 'post',
		data: data
	})
}
// 法律咨询热度
export function nonLegalAdviceRejectHeat(id, type) {
	return request({
		url: '/portal/case/nonLegalAdviceRejectHeat/' + id + '/' + type,
		method: 'get',
	})
}
//获取咨询列表 --获取的是已完成的列表
export function listNonLegalAdvice(data) {
	return request({
		url: '/portal/case/finishListNonLegalAdvice',
		method: 'get',
		params: data
	})
}
//获取我的咨询列表
export function mylistNonLegalAdvice(data) {
	return request({
		url: '/portal/case/mylistNonLegalAdvice',
		method: 'get',
		params: data
	})
}

// 获取咨询详情
export function nonLegalAdviceInfo(id) {
	return request({
		url: "/portal/case/nonLegalAdviceInfo/" + id,
		method: 'get',
	})
}

// 新增文书服务
export function addNonClericalService(data) {
	return request({
		url: "/portal/case/nonLitigation/addNonClericalService",
		method: 'post',
		data: data,
	})
}

// 修改文书服务
export function editNonClericalService(data) {
	return request({
		url: "/portal/case/nonLitigation/editNonClericalService",
		method: 'put',
		data: data,
	})
}
// 获取金额
export function reservationFee(data) {
	return request({
		url: "/portal/case/nonLitigation/nonClericalService/reservationFee",
		method: 'get',
		params: data
	})
}
// 支付    "payMoney":0,"type":0,"orthId":0
export function payLog(data) {
	return request({
		url: "system/payLog",
		method: 'post',
		data: data
	})
}
//获取支付状态
export function getPayStatus(data) {
	return request({
		url: "system/payLog/getPayStatus",
		method: 'get',
		params: data
	})
}

//获取文书列表
export function mylistNonClericalService(data) {
	return request({
		url: '/portal/case/nonLitigation/mylistNonClericalService',
		method: 'get',
		params: data
	})
}
//获取文书详情
export function getInfoNonClericalService(id) {
	return request({
		url: "/portal/case/nonLitigation/getInfoNonClericalService/" + id,
		method: 'get',
	})
}

//下载文书
export function documentUrlNonClericalService(id) {
	return request({
		url: '/portal/case/nonLitigation/documentUrlNonClericalService/' + id,
		method: 'get',
	})
}

//新增案件委托服务
export function addNonCaseEntrustment(data) {
	return request({
		url: "/portal/case/nonLitigation/addNonCaseEntrustment",
		method: 'post',
		data: data,
	})
}
//修改案件委托服务
export function editNonCaseEntrustment(data) {
	return request({
		url: "/portal/case/nonLitigation/editNonCaseEntrustment",
		method: 'put',
		data: data,
	})
}
//获取案件委托列表
export function mylistNonCaseEntrustment(data) {
	return request({
		url: '/portal/case/nonLitigation/mylistNonCaseEntrustment',
		method: 'get',
		params: data
	})
}

//获取案件委托详情
export function getInfoNonCaseEntrustment(id) {
	return request({
		url: '/portal/case/nonLitigation/getInfoNonCaseEntrustment/' + id,
		method: 'get',
	})
}





/* ===================================债权催收================================================== */
// 新增债权催收服务
export function addNonCreditorRights(data) {
	return request({
		url: "/portal/case/nonLitigation/addNonCreditorRights",
		method: 'post',
		data: data,
	})
}
// 修改债权催收服务
export function editNonCreditorRights(data) {
	return request({
		url: "/portal/case/nonLitigation/editNonCreditorRights",
		method: 'put',
		data: data,
	})
}
//获取债权催收列表
export function mylistNonCreditorRights(data) {
	return request({
		url: '/portal/case/nonLitigation/mylistNonCreditorRights',
		method: 'get',
		params: data
	})
}
//获取债权催收详情
export function getInfoNonCreditorRights(id) {
	return request({
		url: "/portal/case/nonLitigation/getInfoNonCreditorRights/" + id,
		method: 'get',
	})
}

//债权催收催告函
export function showReminderPdf(id) {
	return request({
		url: "/portal/case/nonLitigation/reminderLetterNonCreditorRights/" + id,
		method: 'get',
	})
}

// 债权催收反馈意见
export function feedbackNonCreditorRights(id) {
	return request({
		url: "/portal/case/nonLitigation/feedbackNonCreditorRights/" + id,
		method: 'get',
	})
}
// 债权催收快递凭证
export function courierVoucherNonCreditorRights(id) {
	return request({
		url: "/portal/case/nonLitigation/courierVoucherNonCreditorRights/" + id,
		method: 'get',
	})
}
/* 获取可使用债权次数和非模板合同字数 */
export function getNearestCurrentDate(data) {
	return request({
		url: "/portal/case/nonLitigation/getNearestCurrentDate",
		method: 'get',
		data: data
	})
}
/* 获取债权费用 */
export function getFree(data) {
	return request({
		url: "/portal/case/nonLitigation/nonCreditorRights/fee",
		method: 'get',
		data: data
	})
}


//新增诉讼指导
export function addNonAppealService(data) {
	return request({
		url: '/portal/case/nonLitigation/addNonAppealService',
		method: 'post',
		data: data
	})
}
// 修改诉讼指导
export function editNonAppealService(data) {
	return request({
		url: '/portal/case/nonLitigation/editNonAppealService',
		method: 'put',
		data: data
	})
}

// 获取诉讼指导预约金
export function sszdReservationFee(data) {
	return request({
		url: '/portal/case/nonLitigation/nonAppealService/reservationFee',
		method: 'get',
		data: data
	})
}


//获取诉讼指导列表
export function mylistNonAppealService(data) {
	return request({
		url: '/portal/case/nonLitigation/mylistNonAppealService',
		method: 'get',
		params: data
	})
}

// 诉讼指导详情
export function getInfoNonAppealService(id) {
	return request({
		url: "/portal/case/nonLitigation/getInfoNonAppealService/" + id,
		method: 'get',
	})
}
// 下载诉讼指导方案
export function documentUrlNonAppealService(id) {
	return request({
		url: "/portal/case/nonLitigation/documentUrlNonAppealService/" + id,
		method: 'get',
	})
}


/* PDF预览  type - 01 文书，02 诉讼  id */
export function previewPdf(id, type) {
	return request({
		url: "/portal/case/nonLitigation/preview/" + type + '/' + id,
		method: 'post',
	})
}

/* 获取非合同模板免费字数和剩余字数 */
export function freeNumber(data) {
	return request({
		url: "/portal/case/nonLitigation/nonContractReview/freeNumber",
		method: 'get',
		data: data
	})
}


/* 获取服务风险提示数据 */
export function getContentBykey(key) {
	return request({
		url: "/system/riskStatement/getContentBykey/" + key,
		method: 'get'
	})
}

/* 完成-提交阅读状态  */
export function readSubmitNonCreditorRights(data) {
	return request({
		url: '/portal/case/nonLitigation/readSubmitNonCreditorRights',
		method: 'put',
		data: data,
	})
}


/* 债权新流程 */

//* 用户不同意提交意见  reasonRejection-意见 id*/
export function userSubmitNonCreditorRights(data) {
	return request({
		url: '/portal/case/nonLitigation/userSubmitNonCreditorRights',
		method: 'put',
		data: data,
	})
}

//* 用户不同意终止  id*/
export function userEndNonCreditorRights(data) {
	return request({
		url: '/portal/case/nonLitigation/userEndNonCreditorRights',
		method: 'put',
		data: data,
	})
}
//* 用户同意  id*/
export function reminderLetterNonCreditorRights(data) {
	return request({
		url: '/portal/case/nonLitigation/reminderLetterNonCreditorRights',
		method: 'put',
		data: data,
	})
}
export function getFileUrl() {
	return request({
		url: '/file/downloadByUrl?fileUrl=https://file.qiandau.com/statics/2023/05/05/2882_20230505025411A305.pdf',
		method: 'get'
	})
}

/**
 * 获取状态记录日志
 * @param {Object} data
 */
export function getStatusLog(data) {
	return request({
		url: '/case/statusLog/list',
		method: 'get',
		params: data
	})
}


// 文书下载隐藏文件
 export function clericalServicehideFileUrl(data) {
	return request({
		url: "/case/nonClericalService/hideFileUrl",
		method: 'post',
		data: data
	})
}

//诉讼下载文件隐藏文件
 export function AppealServicehideFileUrl(data) {
	return request({
		url: "/case/nonAppealService/hideFileUrl",
		method: 'post',
		data: data
	})
}

/**
 *获取会员续费费用
 */
export function getMemberPrice() {
	return request({
		url: '/portal/case/system/memberFee',
		method: 'get',
	})
}

/* 获取委托案件判决书下载列表 */
export function getPjsDownloadFiles(data) {
	return request({
		url: 'case/nonCaseEntrustment/getDownloadFiles',
		method: 'get',
		params: data
	})
}
/* 设置判决书已下载  id*/
export function setDownloadFileFlag(data) {
	return request({
		url: 'case/nonCaseEntrustment/setDownloadFileFlag',
		method: 'get',
		params: data
	})
}
/* 提交服务评价  id  evaluate=json*/
export function submitEvaluate(data) {
	return request({
		url: 'case/nonCaseEntrustment/submitEvaluate',
		method: 'post',
		data
	})
}


/*  获取法律风险二级 */
export function riskCategoryApi(data) {
	return request({
		url: 'case/riskCategory/list',
		method: 'get',
		data
	})
}


//获取支付参数
export function getPay() {
	return request({
		url: 'case/public/getPay',
		method: 'get'
	})
}
