import request from '@/utils/request'

// 获取查看或下载文件路径 key
export function getFileUrl(data) {
	return request({
		url: 'case/file/getFileUrl',
		method: 'get',
		params: data
	})
}

/* 下载文件 */
export function downloadFile(id, type) {
	return request({
		url: '/case/file/downloadFile/' + id + '/' + type,
		method: 'post'
	})
}

/* 下载文件 */
// type 类型 1=文书 2=诉讼辅助 3=案件委托 4=债权催收 5=合同审查 6=法律咨询
export function nonUpladFileMenu(data) {
	return request({
		url: '/case/nonUpladFileMenu/list',
		method: 'get',
		params: data
	})
}

// 获取操作日志
export function statusLogList(data) {
	return request({
		url: '/case/statusLog/list',
		method: 'get',
		params: data
	})
}
