import request from '@/utils/request'

// 登录方法
export function login(phone, password, code, uuid) {
	return request({
		url: '/auth/login',
		headers: {
			isToken: false
		},
		method: 'post',
		data: {
			phone,
			password,
			code,
			uuid,
			loginType: '02',
			loading: true
		}
	})
}

// 获取短信验证码
export function sendSmsToPhone(data) {
	return request({
		url: '/portal/public/sendSmsToPhone',
		headers: {
			isToken: false
		},
		method: 'post',
		data: data
	})
}


/* 获取当前登录用户信息 */
export function getUserInfo() {
	return request({
		url: 'system/user/getInfo',
		method: 'get',
	})
}

export function logout() {
	return request({
		url: '/user/logout',
		method: 'post'
	})
}

export function register(name, phone) {
	return request({
		url: '/system/userApply/public/add',
		method: 'post',
		data: {
			name,
			phone,
		}
	})
}

/**
 * 获取当前用户及会员到期时间
 */
export function getCurrentUser() {
	return request({
		url: '/system/user/getCurrentUser',
		method: 'get',
	})
}
/**
 *获取会员续费费用
 */
export function getMemberPrice() {
	return request({
		url: '/portal/case/system/memberFee',
		method: 'get',
	})
}


// 用户头像上传
export function uploadAvatar(data) {
	return request({
		url: '/system/user/profile/avatar',
		method: 'post',
		data: data,
		type: "formData"
	})
}



/* 获取用户留言列表 */
export function leaveMessageList(data) {
	return request({
		url: '/portal/leaveMessageList',
		method: 'get',
		params: data
	})
}
/* 删除用户留言列表 */
export function leaveMessageDel(id) {
	return request({
		url: '/portal/leaveMessageDel/'+id,
		method: 'delete'
	})
}

/* 回复 */
export function leaveMessageEdit(data) {
	return request({
		url: '/portal/leaveMessageEdit',
		method: 'put',
		data: data
	})
}

/* 首次登录同意服务条款才可登录 */
export function joinSystem(data) {
	return request({
		url: '/portal/user/joinSystem',
		method: 'put',
		data: data,
		token: ''
	})
}