import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import settings from './modules/settings'
import user from './modules/user'
import pageParams from './pageParams/index.js'
import params from './modules/params.js'
// import api from './modules/api'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		settings,
		user,
		pageParams,
		params
		// api
	}
})
