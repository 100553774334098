<template>
	<div class="pdfView">
		<el-dialog top="0vh" :close-on-click-modal="false" title="预览" :visible.sync="show" width="100%" :modal="false">
			<div class="pdf columnScorll flex-column-center bg-grey" style="height: 99vh;border: 1px solid #ddd;">
				<vue-office-pdf v-if="['pdf'].includes($funs.fileLastSuffix(fileUrl))" :src="fileUrl"
					@rendered="rendered" @error="errorHandler">
				</vue-office-pdf>
				<vue-office-docx v-if="['docx','doc'].includes($funs.fileLastSuffix(fileUrl))" :src="fileUrl"
					style="height: 100%;" @rendered="rendered" />

				<img v-if="['jpg','jpeg','png'].includes($funs.fileLastSuffix(fileUrl)) && fileUrl" :src="fileUrl"
					alt="" :style="{'zoom':zooms,'width':'500px','height': '500px'}">
				<div class="flex-box zoombox" v-if="['jpg','jpeg','png'].includes($funs.fileLastSuffix(fileUrl))">
					<i class="el-icon-zoom-in fontColor-white font30 pointer" title="放大" @click="zoomFun(1)"></i>
					<i class="el-icon-zoom-out ml-30 fontColor-white font30 pointer" title="缩小" @click="zoomFun(2)"></i>
				</div>
				
				
				<div class="pdf flex-center" v-if="['mp4'].includes($funs.fileLastSuffix(fileUrl))"
					style="height:90vh;border: 1px solid #ddd;">
					 <video width="800" height="350" style="background-color: black;"  controls>
					    <source :src="fileUrl" type="video/mp4">
							您的浏览器不支持播放视频.
					  </video>
				</div>
			</div>
			
			
			<el-button  type="primary" class="btn border-none" color="#dc7070" @click="show=false">关闭
			</el-button>

		</el-dialog>
	</div>
</template>
<script>
	//引入VueOfficeDocx组件
	import VueOfficeDocx from '@vue-office/docx'
	import VueOfficePdf from '@vue-office/pdf'
	//引入相关样式
	import '@vue-office/docx/lib/index.css';
	import {
		getFileUrl
	} from '@/api/common'
	import {
		Loading
	} from 'element-ui'
	export default {
		name: 'Pdf',
		components: {
			VueOfficeDocx,
			VueOfficePdf
		},
		data() {
			return {
				fileUrl: "",
				pageNum: 1,
				pageTotalNum: 1,
				pageRotate: 0,
				// 加载进度
				loadedRatio: 0,
				curPageNum: 0,
				show: false,
				loading: null,
				zooms: 1
			}
		},
		mounted() {
			$('.pdfView .el-dialog').css('height', window.innerHeight - 20)
			// this.fileUrl = this.pdfUrl ? this.pdfUrl : ''
		},
		methods: {
			zoomFun(type) {
				if (type == 1) {
					this.zooms += 0.2
				} else {
					this.zooms -= 0.2;
					if (this.zooms <= 1) {
						this.zooms = 1
					}
				}
			},
			closeDiog() {
				this.pdfUrl = '';
				this.endLoading()
				this.show = false;
			},
			/* 获取文件路径 */
			getFileUrl(keys) {
				this.fileUrl = ''
				if(!keys){
					this.$message({
						title: '',
						message: '文件为空！',
						type: 'error'
					});
					return 
				}
				if (keys.indexOf('http') != -1) {
					this.fileUrl = keys;
					this.show = true;
					return
				}
				getFileUrl({
					key: keys
				}).then(res => {
					this.fileUrl = res.data;
					this.show = true;
					if (this.fileUrl) {
						this.endLoading()
					}
				})
			},
			rendered(e) {
				console.log("渲染完成");
				this.endLoading()
			},
			errorHandler(e) {
				console.log("渲染失败")
				this.endLoading()
			},
			prePage() {
				var p = this.pageNum
				p = p > 1 ? p - 1 : this.pageTotalNum
				this.pageNum = p
			},
			nextPage() {
				var p = this.pageNum
				p = p < this.pageTotalNum ? p + 1 : 1
				this.pageNum = p
			},
			clock() {
				this.pageRotate += 90
			},
			counterClock() {
				this.pageRotate -= 90
			},
			password(updatePassword, reason) {
				updatePassword(prompt('password is "123456"'))
				console.log('...reason...')
				console.log(reason)
				console.log('...reason...')
			},
			pageLoaded(e) {
				this.curPageNum = e
			},
			pdfError(error) {
				console.error(error)
			},
			pdfPrintAll() {
				this.$refs.pdf.print()
			},
			pdfPrint() {
				this.$refs.pdf.print(100, [1, 2])
			},
			// loading开始 方法
			startLoading() {
				// element-ui loading 服务调用方式  
				this.loading = Loading.service({
					lock: true,
					text: '加载中...',
					spinner: 'el-icon-loading', // 自定义图标
					background: 'rgba(0, 0, 0, 0.7)'
				})
			},
			// loading结束 方法
			endLoading() {
				// this.loading.close()
			}

		}
	}
</script>

<style scoped lang="scss">
	.pdfView {
		.pdf {}

		.btn-def {
			border-width: 1px solid #000;
			padding: 6px 15px;
			background: #00aaff;
			color: #fff;
			font-size: 17px;
			border-radius: 16px;
			cursor: pointer;
		}
	}

	::v-deep .pdfView .v-modal {
		display: none;
	}

	::v-deep .pdfView .el-dialog {
		height: 100vh !important;
	}

	::v-deep .pdfView .el-dialog__header {
		display: block !important;
	}

	::v-deep .vue-office-pdf {
		width: 40% !important;
		z-index: 9999;

		canvas {
			width: 100% !important;
		}
	}


	::v-deep .vue-office-pdf-wrapper {
		display: flex !important;
		flex-direction: column !important;
	}

	::v-deep .docx {
		width: 80% !important;
	}

	.zoombox {
		position: fixed;
		right: 10%;
		top: 50%;
		transform: translateY(-50%);
	}
	
	.btn{
		width:150px;
		height: 50px;
		position: absolute;
		right: 30px;
		bottom:50px;
		background-color: #ffffff;
		border: 1px solid #000;
		color: #000;
	}
</style>