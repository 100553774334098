<template>
	<div class="app-wrapper">
		<navbar />
		<div class="app-container">
			<el-scrollbar class="sidebar-container" wrap-class="default-scrollbar__wrap">
				<div class="menu-container">
					<router-link to="/legal-risk">
						<div class="menu-wrapper">
							<div class="menu-item">
								<img class="menu-icon" :src="require('@/icons/svg/menu.svg')" />
							</div>
							<p>法律风险体检</p>
						</div>
					</router-link>
					<router-link to="/legal-advice">
						<div class="menu-wrapper">
							<div class="menu-item2">
								<img class="menu-icon" :src="require('@/icons/svg/menu2.svg')" />
							</div>
							<p>法律咨询</p>
						</div>
					</router-link>
					<router-link to="/debt-collection">
						<div class="menu-wrapper">
							<div class="menu-item3">
								<img class="menu-icon" :src="require('@/icons/svg/menu3.svg')" />
							</div>
							<p>律师催告</p>
						</div>
					</router-link>
					<router-link to="/dispute">
						<div class="menu-wrapper">
							<div class="menu-item4">
								<img class="menu-icon" :src="require('@/icons/svg/menu4.svg')" />
							</div>
							<p>纠纷处理</p>
						</div>
					</router-link>
					<router-link to="/contract-manage">
						<div class="menu-wrapper">
							<div class="menu-item5">
								<img class="menu-icon" :src="require('@/icons/svg/menu5.svg')" />
							</div>
							<p>合同管理</p>
						</div>
					</router-link>
				</div>
				<!-- <div class="dot-container">
					<div class="dot-icon"></div>
					<div class="dot-icon2"></div>
					<div class="dot-icon2"></div>
				</div> -->
			</el-scrollbar>
			<div class="main-container">
				<app-main />
			</div>
		</div>
		<bottomView></bottomView>
	</div>
</template>

<script>
	import bottomView from "@/components/bottomView"
	import {
		Navbar,
		AppMain
	} from './components'

	export default {
		name: 'Layout',
		components: {
			Navbar,
			AppMain,
			bottomView
		},
		computed: {
			fixedHeader() {
				return this.$store.state.settings.fixedHeader
			},
		},
		methods: {

		}
	}
</script>

<style lang="scss">
	@import "~@/styles/mixin.scss";
	@import "~@/styles/variables.scss";
	
	.app-wrapper {
		@include clearfix;
		position: relative;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		background-color: $menuBg;
	}

	.app-container {
		background-color: #fafafa;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		padding-bottom: 50px;
	}

	.sidebar-container {
		transition: width 0.28s;
		// min-width: $sideBarWidth!important;
		// max-width: $sideBarWidth!important;
		max-width: $sideBarWidth!important;
		background-color: $menuBg;
		min-height: 100%;
		z-index: 1001;
		overflow-x: hidden !important;
	}
	
	.main-container{
		width: calc(100% - $sideBarWidth);
		// width:75%;
		// width: auto;
		height: calc(100vh - 250px);
		background-color: $menuBg;
		padding: 0 20px;
		overflow: hidden;
		overflow-y: auto;
	}

	.el-scrollbar__wrap.default-scrollbar__wrap {
		overflow-x: auto;
	}

	.dot-container {
		position: absolute;
		top: calc(50% - 60px);
		right: 20px;
		height: 120px;
		display: flex;
		flex-direction: column;

		.dot-icon {
			width: 20px;
			height: 20px;
			background: #909090;
			border-radius: 50%;
		}

		.dot-icon2 {
			width: 20px;
			height: 20px;
			background: #D0D0D0;
			border-radius: 50%;
			margin-top: 20px;
		}
	}


	.menu-container {
		display: flex;
		flex-wrap: wrap;
	}

	.menu-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 30px;
		margin-bottom: 20px;
	}

	.menu-item {
		width: 110px;
		height: 110px;
		position: relative;
		background: linear-gradient(to bottom, #4374b9, #2d4f97);
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
	}

	.menu-item2 {
		width: 110px;
		height: 110px;
		position: relative;
		background: linear-gradient(to bottom, #43b948, #2d9768);
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
	}

	.menu-item3 {
		width: 110px;
		height: 110px;
		position: relative;
		background: linear-gradient(to bottom, #d28053, #bd8645);
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
	}

	.menu-item4 {
		width: 110px;
		height: 110px;
		position: relative;
		background: linear-gradient(to bottom, #f26161, #95493a);
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
	}

	.menu-item5 {
		width: 110px;
		height: 110px;
		position: relative;
		background: linear-gradient(to bottom, #6266bf, #112375);
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 30px;
	}

	.menu-icon {
		width: 50px;
		height: 50px;
		z-index: 100;
	}
	
	
	.bottom-text {
		margin-left: 30px;
		margin-right: 30px;
		font-size: 14px;
		color: #828282;
	}
</style>
