import {
	login,
	logout,
	getUserInfo
} from '@/api/user'
import {
	getToken,
	setToken,
	removeToken,
	getKey,
	setKey
} from '@/utils/auth'
import {
	resetRouter
} from '@/router'
import axios from 'axios'
import qs from 'qs'
import {
	MessageBox,
	Message
} from 'element-ui';

const getDefaultState = () => {
	return {
		token: getToken(),
		nickeName: '',
		avatar: '',
		key: getKey(),
		userId:localStorage.getItem('userId')?localStorage.getItem('userId'):'',//用户Id
		userInfo:localStorage.getItem('userInfo')?localStorage.getItem('userInfo'):'',//用户信息
		refashData:'',//页面刷新判断
		vipEndTimeStatus:false,//是否会员到期
	}
}

const state = getDefaultState()

const mutations = {
	RESET_STATE: (state) => {
		Object.assign(state, getDefaultState())
	},
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_NICKNAME: (state, name) => {
		state.nickeName = name
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_KEY: (state, data) => {
		state.key = data
	},
	SET_USERID: (state, data) => {
		state.userId = data
		localStorage.setItem('userId',data)
	},
	SET_USERINFO: (state, data) => {
		state.userInfo = data;
		localStorage.setItem('userInfo',data)
	},
	SET_REFASHDATA: (state, data) => {
		state.refashData = data
	},
	SET_ENDTIMESTATUS: (state, data) => {
		state.vipEndTimeStatus = data
	}
}

const actions = {
	login({
		commit
	}, userInfo) {
		const phone = userInfo.phone.trim()
		const password = userInfo.password
		const code = userInfo.code
		const uuid = userInfo.uuid
		return new Promise((resolve, reject) => {
			login(phone, password, code, uuid).then(response => {
				const {
					data
				} = response;
				// commit('SET_TOKEN', data.access_token);
				commit('SET_KEY', data.key);
				// setToken(data.access_token);
				setKey(data.key);
				var pars = {
					type: 'login',
					pars: {
						id: data.key
					}
				};
				resolve(response)
				userInfo.that.$socketPublic.dispatch('webSocketSend', pars); //初始化ws
			}).catch(error => {
				reject(error)
			})
		})
	},
	// get user info
	getInfo({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			getUserInfo().then(response => {
				console.log("response----",response);
				const {
					user
				} = response
				if (!user) {
					return reject('Verification failed, please Login again.')
				}
				const {
					nickeName,
					avatar,
					userId
				} = user
				
				console.log("user111111----",user);
				commit('SET_NICKNAME', nickeName)
				commit('SET_AVATAR', avatar)
				commit('SET_USERID', userId)
				commit('SET_USERINFO', JSON.stringify(user))
				setTimeout(()=> {
					resolve(user)
				}, 500);
			}).catch(error => {
				reject(error)
			})
		})
	},

	// user logout
	logout({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			logout(state.token).then(() => {
				removeToken() // must remove  token  first
				resetRouter()
				commit('RESET_STATE')
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// remove token
	resetToken({
		commit
	}) {
		return new Promise(resolve => {
			removeToken()
			commit('RESET_STATE')
			resolve()
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}