import {
	Message
} from 'element-ui'
import store from '@/store/index.js'
import axios from 'axios'

import global from '@/utils/globalVariable.js';
import {
	getFileUrl
} from '@/api/common'
import {
	getFileCode,
	filePreview,
	downloadFile
} from "@/api/common.js"
let funs = {
	getNowTime(str) {
		let dt = new Date()
		let y = dt.getFullYear()
		let mt = (dt.getMonth() + 1).toString().padStart(2, '0')
		let day = dt.getDate().toString().padStart(2, '0')
		let h = dt.getHours().toString().padStart(2, '0')
		let m = dt.getMinutes().toString().padStart(2, '0')

		let d = dt.getDay(); //  周一返回的是1，周六是6，但是周日是0
		let arr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六", ];
		let week = arr[d];
		let nowtime = "";
		if (str == 'yy-mm-dd') {
			nowtime = y + "-" + mt + "-" + day
		} else if (str == 'hh-mm') {
			nowtime = h + ":" + m
		} else if (str == 'mm-dd') {
			nowtime = mt + "-" + day
		} else if (str == 'week') {
			nowtime = week
		} else {
			nowtime = y + "-" + mt + "-" + day + " " + h + ":" + m
		}

		return nowtime;
		console.log('此刻时间', this.nowtime)
	},
	/* 获取屏幕高度 */
	getScreenHeight() {
		return document.body.clientHeight;
	},

	/* 时间格式化 */
	transformTimestamp(timestamp) {
		let a = new Date(timestamp).getTime();
		const date = new Date(a);
		const Y = date.getFullYear() + '-';
		const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
		const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
		const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
		const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()); // 秒
		const dateString = Y + M + D + h + m + s;
		// console.log('dateString', dateString); // > dateString 2021-07-06 14:23
		return dateString;
	},

	/* 随机数*/
	uuid(str) {
		var s = [];
		var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		for (var i = 0; i < 32; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23];
		var uuid = s.join("");
		return uuid;
	},


	/* 文件下载*/
	downloadEvt(fileurl) {
		if (!fileurl) {
			Message({
				message: '无文件可下载！',
				type: 'error'
			});
			return
		}
		Message({
			message: '正在下载！',
			type: 'info'
		});
		getFileUrl({
			key: fileurl
		}).then(res => {
			var fileName = res.data.substring(res.data.lastIndexOf('/') + 1) //文件名截取
			const el = document.createElement('a');
			el.style.display = 'none';
			el.setAttribute('target', '_blank');
			/**
			 * download的属性是HTML5新增的属性
			 * href属性的地址必须是非跨域的地址，如果引用的是第三方的网站或者说是前后端分离的项目(调用后台的接口)，这时download就会不起作用。
			 * 此时，如果是下载浏览器无法解析的文件，例如.exe,.xlsx..那么浏览器会自动下载，但是如果使用浏览器可以解析的文件，比如.txt,.png,.pdf....浏览器就会采取预览模式
			 * 所以，对于.txt,.png,.pdf等的预览功能我们就可以直接不设置download属性(前提是后端响应头的Content-Type: application/octet-stream，如果为application/pdf浏览器则会判断文件为 pdf ，自动执行预览的策略)
			 */
			fileName && el.setAttribute('download', fileName);
			el.href = res.data;
			document.body.appendChild(el);
			el.click();
		})
	},

	/* 获取当前时间 */
	getNowDate() {
		const timeOne = new Date()
		const year = timeOne.getFullYear()
		let month = timeOne.getMonth() + 1
		let day = timeOne.getDate()
		month = month < 10 ? '0' + month : month
		day = day < 10 ? '0' + day : day
		const NOW_MONTHS_AGO = `${year}-${month}-${day}`
		return NOW_MONTHS_AGO
	},

	// 验证手机号码
	checkPhone(tel) {
		var rtn = false;
		//移动号段
		var regtel = /^((19[0-9])|(13[4-9])|(15([0-2]|[7-9]))|(18[2|3|4|7|8])|(178)|(147))[\d]{8}$/;
		if (regtel.test(tel)) {
			rtn = true;
		}
		//电信号段
		regtel = /^((133)|(153)|(18[0|1|9])|(177))[\d]{8}$/;
		if (regtel.test(tel)) {
			rtn = true;
		}
		//联通号段
		regtel = /^((13[0-2])|(145)|(15[5-6])|(176)|(18[5-6]))[\d]{8}$/;
		if (regtel.test(tel)) {
			rtn = true;
		}
		if (!rtn) {
			Message({
				message: '请填写正确的手机号码！',
				type: 'error'
			});
		}
		return rtn;
	},

	/* 身份证验证 */
	checkIdNum(value) {
		// const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/ 
		const reg = /^\w{18}$/
		if (!value) {
			Message({
				message: '证件号码不能为空！',
				type: 'error'
			});
			return false
		} else if (!reg.test(value)) {
			Message({
				message: '证件号码不正确！',
				type: 'error'
			});
			return false
		} else {
			return true
		}
	},

	/* 返回首页 */
	gotoIndex() {
		this.$router.push('/home')
	},

	/* 截取文件后缀名 */
	fileLastSuffix(url) {
		let pos = url.lastIndexOf('/') // '/'所在的最后位置
		let str = url.substr(pos + 1) //截取文件名称和后缀   输出：香菜.png
		let name = str.substring(str.lastIndexOf('.') + 1)
		return name;
	},
	/* 获取文件名 */
	getFileName(url) {
		let file = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
		console.log(file);
		return file
	},
	/* 判断是否为空 */
	isNull(str) {
		if (str == '' || str == undefined || str == null) {
			return false
		} else {
			return true
		}
	},
	/* 文件预览 */
	preview(keys) {
		if (!keys) {
			Message({
				message: '文件错误，无法打开！',
				type: 'error'
			})
			return
		}
		let typeArr = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'jpg', 'jpeg', 'png'];
		let newArr = ['jpg', 'png', 'jpeg', 'pdf']
		getFileUrl({
			key: keys
		}).then(res => {
			let url = '';
			let fileurl = res.data;
			console.log("url", fileurl);
			if (newArr.includes(keys) != -1) {
				url = 'https://api.boot.jeecg.com/generic/web/viewer.html?file=' + fileurl;
			} else {
				url = 'http://view.officeapps.live.com/op/view.aspx?src=' + fileurl;
			}
			let width = 1000;
			const height = 800;
			let top = (window.screen.availHeight - height) / 2;
			let left = (window.screen.availWidth - width) / 2;
			window.open(url, '', 'width=' + width + ',height=' + height + ',top=' + top + ',left=' +
				left);

		})
	},

	customStyle() {
		return {
			background: '#2A6EFD',
			color: '#fff',
			fontSize: '32rpx'
		}
	},

	/* 获取文件路径 */
	getFileUrl(fileurl) {
		return new Promise((resolve, reject) => {
			getFileUrl({
				key: fileurl
			}).then(res => {
				resolve(res.data)
			})
		})
	},
	/* 获取路径 */
	async getPdfFileUrl(keys) {
		try {
			const response = await getFileUrl({
				key: keys
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	}

}



export default funs